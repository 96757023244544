.page {
  /* background-color: #4e5045; */
  /* color: whitesmoke; */
  padding: 0 5%;
}

.page > a {
  display: inline-block;
  font-size: 25px;
  margin: 10px 0;
}

.page > h1 {
  margin: 50px 0 35px;
}
.page > hr {
  margin: 50px auto 70px;
}

/* .page > p {
  margin: 40px 5% 10px;
  font-size: 32px;
  font-weight: bold;
} */

/* .page > .contain {
  width: 90%;
  margin-right: 5%;
} */
