@font-face {
  font-family: 'Midan';
  src: url('./fonts/Midan.ttf') format('truetype');
}
*{
  padding: 0;
  margin: 0;
  
  font-family: 'Midan'!important;
 }
 body{
  direction: rtl;
  background-color:#eff6e0 !important ;
 }
 .container-page{
  background-color: #eff6e0;
}
.book-link img{
  margin: 20px auto !important;
}
p{
  text-align: justify;
  text-justify: inter-word;
  font-size: 1.8rem ;
}
.king_content p{
  font-size: calc(1.3rem + .6vw)!important;
}
#pdf-pagination{
  display: flex;
  flex-direction: row-reverse;
}
#doc-nav{
  direction: ltr;
}
.video-react .video-react-big-play-button{
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
}
h1{
  text-align: center;
}