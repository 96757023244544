header{
    background-color: #eff6e0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url( '../../media/home_page/page0020_page-0001.jpg' ) !important;
    background-size: 100% 100%;;
    background-position: center;
}
.top-header{
    justify-content: space-between;
    align-items: center;
}
.site-title{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.dropdown-toggle{
    direction: ltr;
}